export const MOONPAY_API_KEY = 'pk_live_k6WSd0AaHVEXPV4WlBBrsvRMrAhRH';

export const BUY_BITCOIN_URL = 'https://buy.bitcoin.com';

export const PRICES_API: { main: string; fallback: string } = {
  main: 'https://markets.api.bitcoin.com/live/bitcoin',
  fallback: 'https://apimarkets.btctest.net/live/bitcoin',
};

export const MAXMIND_URL =
  'https://neko-web.api.wallet.bitcoin.com/api/v1/location/info';

export const LOCALES = [
  'en',
  'es',
  'de',
  'it',
  'fr',
  'ru',
  'zh',
  'ja',
  'pt',
  'ko',
  'ar',
  'vn',
  'fi',
  'no',
  'sv',
  'da',
];
