import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';
import MobileMenu from './MobileMenu';
import MobileMenuButton from './MobileMenuButton';
import {
  SearchBarWrapperResponsive,
  SearchBarResponsive,
} from '../Content/styled';
import { BarsSolid } from '../Icons';

const StyledButton = styled(Button)`
  height: 40px;

  & > div {
    height: calc(100% - 1px);
  }
`;

const MobileWrapper = styled.div`
  ${media.xl`
    display: none;
  `}
`;
interface MobileNavProps {
  contrast?: boolean;
  limitTo?: {
    paths: string[];
    keywords: (string | number)[];
    groups?: string[];
    et_route?: string;
  };
  links: LinksStrings[];
  extraLink?: ReactNode;
  buttons: ButtonsStrings[];
  locale: string;
  showSearchBar: boolean;
}

interface MobileNavState {
  isVisible: boolean;
}

class MobileNav extends Component<MobileNavProps, MobileNavState> {
  constructor(props: MobileNavProps) {
    super(props);

    this.state = {
      isVisible: false,
    };

    this.handleVisibleState = this.handleVisibleState.bind(this);
  }

  handleVisibleState = (): void => {
    const { isVisible } = this.state;

    if (isVisible && document) {
      document.body.removeAttribute('style');
    } else {
      document.body.setAttribute('style', 'height: 100vh; overflow:hidden;');
    }

    this.setState({ isVisible: !isVisible });
  };

  render() {
    const { isVisible } = this.state;
    const {
      contrast,
      links,
      extraLink,
      buttons,
      locale,
      showSearchBar,
      limitTo,
    } = this.props;

    const btn = (
      <>
        {showSearchBar && (
          <SearchBarWrapperResponsive>
            <SearchBarResponsive locale={locale} limitTo={limitTo} />
          </SearchBarWrapperResponsive>
        )}

        {buttons.length === 1 && !links.length ? (
          <MobileWrapper>
            <StyledButton
              design="primary"
              className="universal-menu-link"
              href={buttons[0]?.url}
              size="small"
            >
              <span>{buttons[0].label}</span>
            </StyledButton>
          </MobileWrapper>
        ) : (
          <MobileMenuButton
            type="button"
            onClick={this.handleVisibleState}
            contrast={contrast}
            aria-label="Open menu"
          >
            <BarsSolid />
          </MobileMenuButton>
        )}
      </>
    );

    if (isVisible)
      return (
        <MobileMenu
          contrast={contrast}
          hideMobileMenu={this.handleVisibleState}
          links={links}
          extraLink={extraLink}
          buttons={buttons}
        />
      );

    return btn;
  }
}

export default MobileNav;
