import {
  css,
  SimpleInterpolation,
  FlattenSimpleInterpolation,
} from 'styled-components';

interface Breakpoints {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

const breakpoints: Breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const media = Object.keys(breakpoints).reduce((acc, label: string) => {
  const testLabel = label as keyof Breakpoints;
  if (typeof label !== 'string') return acc;

  acc[testLabel] = (
    literals: TemplateStringsArray,
    ...placeholders: SimpleInterpolation[]
  ): FlattenSimpleInterpolation =>
    css`
      @media (min-width: ${breakpoints[testLabel] / 16}em) {
        ${css(literals, ...placeholders)}
      }
    `;
  return acc;
}, {} as Record<keyof Breakpoints, (l: TemplateStringsArray, ...p: SimpleInterpolation[]) => FlattenSimpleInterpolation>);

export default media;
