import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

import media from '../../../media';

interface SublinkProps {
  contrast?: boolean;
}

export const Sublink = styled.a<SublinkProps>`
  ${({ contrast }): FlattenSimpleInterpolation => css`
    position: relative;
    border-bottom: 1px solid rgba(138, 148, 166, 0.11);
    padding: 10px 0;
    color: ${contrast ? colors.solid.white : colors.solid.textOne};
    text-decoration: none;
    display: block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: 0px;
    cursor: pointer;
    margin: 0 24px;
    white-space: nowrap;
    :last-child {
      border-bottom: none;
    }
    ${media.md`
      padding: 16px 0;
    `}
  `}
`;

export const SubmenuWrapper = styled.div`
  text-align: left;
  z-index: 100;
  ${media.xl`
    display: flex;
    width: 349px;
    position: fixed;
    & > div {
      display: flex;
      margin: 0;
      margin-top: 50px;
      justify-content: center;
    }
    & > div > div {
      flex: unset;
    }
  `}
`;

export const Overlay = styled.div`
  ${media.xl`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  `}
`;
