export const sendGaEvent = (
  {
    category = 'buy',
    action,
    label,
  }: { category: string; action: string; label: string },
  cb?: () => void,
): void => {
  if (window && window.ga) {
    let options: { hitCallback: () => void };
    if (cb !== undefined) options = { hitCallback: cb };

    // Queues a tracker object for creation.
    window.ga('create', 'UA-59964190-1', 'auto', 'bitcoincom-www');

    // Once the tracker has been created, send hit to GA
    window.ga(() => {
      const tracker = window.ga.getByName('bitcoincom-www');
      tracker.send('event', category, action, label, options);
    });
  } else if (cb !== undefined) {
    cb();
  }
};
