import en from './en.json';
import es from './es.json';
import ja from './ja.json';
import ru from './ru.json';
import zh from './zh.json';
import de from './de.json';
import it from './it.json';
import fr from './fr.json';
import pt from './pt.json';
import ko from './ko.json';
import ar from './ar.json';
import fi from './fi.json';
import vn from './vn.json';
import no from './no.json';
import sv from './sv.json';
import da from './da.json';

const i18n: Record<string, any> = {
  en,
  es,
  de,
  it,
  fr,
  ru,
  zh,
  ja,
  pt,
  ko,
  ar,
  vn,
  fi,
  no,
  sv,
  da,
};

export default i18n;
