import React, { FunctionComponent, useState } from 'react';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import { Sublink, SubmenuWrapper, StyledCard, Header } from './styled';
import { SubmenuHeader } from '../styled';
import SublinkComponent from './Sublink/Sublink';

const Submenu: FunctionComponent<{
  id: string;
  links: LinksStrings[];
  contrast?: boolean;
  isMobile?: boolean;
  linkIcon?: string;
}> = ({ id, contrast, isMobile, links, linkIcon }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const handleMouseState = () => {
    setShowSubmenu(!showSubmenu);
  };

  return (
    <SubmenuHeader
      contrast={contrast}
      className="universal-menu-link"
      onMouseEnter={handleMouseState}
      onMouseLeave={handleMouseState}
    >
      <Header>
        {linkIcon && (
          <Image src={linkIcon} alt="verse logo" height={23} width={23} />
        )}
        {id}
      </Header>
      {showSubmenu && (
        <>
          <SubmenuWrapper>
            {!isMobile && (
              <StyledCard contrast={contrast}>
                {links.map(
                  ({ id: sublinkId, url, subheading, icon, alt, text }) => {
                    return (
                      <span key={sublinkId}>
                        <SublinkComponent
                          sublinkId={sublinkId}
                          url={url}
                          subheading={subheading}
                          icon={icon}
                          alt={alt}
                          text={text}
                        />
                      </span>
                    );
                  },
                )}
              </StyledCard>
            )}
            {isMobile &&
              links.map(({ id: sublinkId, url }) => {
                return (
                  <Sublink
                    key={sublinkId}
                    href={url}
                    contrast={contrast}
                    className="universal-menu-link sublink"
                    rel="canonical"
                  >
                    <span>{sublinkId}</span>
                  </Sublink>
                );
              })}
          </SubmenuWrapper>
        </>
      )}
    </SubmenuHeader>
  );
};

export default Submenu;
