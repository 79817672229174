import React, { FunctionComponent } from 'react';
import Button from '@bitcoin-portal/bitcoincom-pkg-components/dist/Button';

import { ButtonWrapper, Wrapper, Link } from './styled';
import { sendGaEvent } from '../../helpers';
import btcLogo from '../../assets/bitcoin-logo.png';

const Buttons: FunctionComponent<{
  contrast?: boolean;
  isMobile?: boolean;
  buttons: ButtonsStrings[];
}> = ({ contrast = false, isMobile = false, buttons }) => {
  return (
    <Wrapper isMobile={isMobile}>
      <ButtonWrapper isMobile={isMobile}>
        {buttons.map(({ id, label, url, design }) =>
          id === 'login' ? (
            <Link
              key={id}
              contrast={contrast}
              href={url}
              onClick={(): void => {
                sendGaEvent({
                  category: 'Account',
                  action: 'Sign in',
                  label: 'Header',
                });
              }}
            >
              <span>{label}</span>
            </Link>
          ) : (
            <Button
              key={id}
              design={design}
              className="universal-menu-link"
              href={url}
              size="small"
              onClick={(): void => {
                if (id === 'create') {
                  sendGaEvent({
                    category: 'Account',
                    action: 'Create account',
                    label: 'Header',
                  });
                }
              }}
            >
              <span>
                <img src={btcLogo} width={24} height={24} alt="btc logo" />
                {label}
              </span>
            </Button>
          ),
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Buttons;
