import React, { ReactElement } from 'react';

export const BarsSolid = (): ReactElement => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icMenuFill">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.81738C0 3.2651 0.447715 2.81738 1 2.81738H15C15.5523 2.81738 16 3.2651 16 3.81738C16 4.36967 15.5523 4.81738 15 4.81738H1C0.447715 4.81738 0 4.36967 0 3.81738ZM0 8.81738C0 8.2651 0.447715 7.81738 1 7.81738H15C15.5523 7.81738 16 8.2651 16 8.81738C16 9.36967 15.5523 9.81738 15 9.81738H1C0.447715 9.81738 0 9.36967 0 8.81738ZM1 12.8174C0.447715 12.8174 0 13.2651 0 13.8174C0 14.3697 0.447715 14.8174 1 14.8174H15C15.5523 14.8174 16 14.3697 16 13.8174C16 13.2651 15.5523 12.8174 15 12.8174H1Z"
        fill="black"
        style={{ fill: 'black', fillOpacity: 1 }}
      />
    </g>
  </svg>
);

export const TimesSolid = (): ReactElement => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Pages" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Mobile---Menu"
        transform="translate(-284.000000, -18.000000)"
        fill="currentColor"
      >
        <path
          d="M294,26 L304,26 L304,28 L294,28 L294,38 L292,38 L292,28 L282,28 L282,26 L292,26 L292,16 L294,16 L294,26 Z"
          id="Combined-Shape"
          transform="translate(293.000000, 27.000000) rotate(-45.000000) translate(-293.000000, -27.000000) "
        />
      </g>
    </g>
  </svg>
);
