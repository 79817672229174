import styled from 'styled-components';
import media from '../../media';

interface VideoProps {
  display: string;
}

export const Image = styled.img`
  min-width: 135px;
  min-height: 20px;

  height: 16px;
  width: 108px;
  object-fit: contain;
  vertical-align: middle;
  background-color: transparent !important;

  ${media.md`
    height: 20px;
    width: 135px;
    vertical-align: unset;
    margin-top: 2px;
  `}
`;

export const Video = styled.video<VideoProps>`
  position: absolute;
  display: ${props => props.display || 'block'};
  left: 0px !important;
  top: 50%;
  transform: translateY(-50%);
  min-width: 135px;
  min-height: 20px;
  height: 16px;
  width: 108px;
  object-fit: contain;
  vertical-align: middle;
  background-color: transparent !important;

  ${media.md`
    height: 20px;
    width: 135px;
    vertical-align: unset;
    margin-top: 1px;
  `}
`;
