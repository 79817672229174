import React, { Component, ReactNode } from 'react';
import Sticky from 'react-stickynode';

import { OuterNavWrapper, StickyNavWrap, NavContent } from './styled';

interface StickyContainerProps {
  contrast?: boolean;
  children: ReactNode;
}

interface StickyContainerState {
  sticky: boolean;
}

class StickyContainer extends Component<
  StickyContainerProps,
  StickyContainerState
> {
  constructor(props: StickyContainerProps) {
    super(props);

    this.state = {
      sticky: false,
    };

    this.handleStateChange = this.handleStateChange.bind(this);
  }

  handleStateChange = ({ status }: { status: number }): void => {
    const isSticky = status === 2;

    this.setState({ sticky: isSticky });

    if (window && window.localStorage)
      window.localStorage.setItem('sticky-state', isSticky.toString());
  };

  render() {
    const { children, contrast, ...rest } = this.props;
    const { sticky } = this.state;

    return (
      <OuterNavWrapper sticky={sticky} contrast={contrast} {...rest}>
        <Sticky onStateChange={this.handleStateChange}>
          <StickyNavWrap sticky={sticky} contrast={contrast}>
            <NavContent>{children}</NavContent>
          </StickyNavWrap>
        </Sticky>
      </OuterNavWrapper>
    );
  }
}

export default StickyContainer;
