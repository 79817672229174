import { useMemo } from 'react';
import { useGeoLocation } from './useGeoLocation';
import restrictions from '../restrictions';

export const useLinks = (links: LinksStrings[]) => {
  const geo = useGeoLocation();

  const allLinks: LinksStrings[] = useMemo(() => {
    // dont show restricted items if no geo (dont show restricted just in case)
    if (!geo) return links.filter(l => !l.restricted);

    // filter out replacement items
    const replacements = links
      .filter(l => l.restricted)
      .reduce((p, c) => {
        if (!c.restricted) return p;
        return {
          ...p,
          [c.restricted.replace]: restrictions[c.restricted.map],
        };
      }, {} as { [replace: string]: string[] });

    return links.reduce((p, c) => {
      // remove restricted items
      if (c.restricted && restrictions[c.restricted.map]?.includes(geo)) {
        return p;
      }

      // replace items if unrestrected
      if (
        Object.keys(replacements)?.includes(c.key) &&
        !replacements[c.key]?.includes(geo)
      ) {
        return p;
      }

      // no filtering required
      return [...p, c];
    }, [] as LinksStrings[]);
  }, [geo, links]);

  return allLinks;
};
