import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

import Card from '@bitcoin-portal/bitcoincom-pkg-components/dist/Card';
import media from '../../../media';

interface CardProps {
  contrast?: boolean;
}

export const StyledCard = styled(Card)<CardProps>`
  ${({ contrast }): FlattenSimpleInterpolation => css`
    background-color: ${contrast ? colors.solid.textOne : colors.solid.white};
    color: ${contrast ? colors.solid.white : colors.solid.textOne};
  `}
`;

interface SubheadingProps {
  contrast?: boolean;
}

export const Subheading = styled.div<SubheadingProps>`
  ${({ contrast }): FlattenSimpleInterpolation => css`
    color: ${contrast ? colors.solid.textFour : 'grey'};
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    white-space: normal;
  `}
`;

interface SublinkProps {
  contrast?: boolean;
}

export const Sublink = styled.a<SublinkProps>`
  ${({ contrast }): FlattenSimpleInterpolation => css`
    position: relative;
    border-bottom: 1px solid rgba(138, 148, 166, 0.11);
    padding: 10px 0;

    color: ${contrast ? colors.solid.white : colors.solid.textOne};

    text-decoration: none;
    display: block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    letter-spacing: 0px;
    cursor: pointer;
    margin: 0 24px;
    white-space: nowrap;

    & img {
      margin-right: 10px;
    }

    :last-child {
      border-bottom: none;
    }

    ${media.md`
      padding: 16px 0;
    `}
  `}
`;

export const SubmenuWrapper = styled.div`
  text-align: left;
  z-index: 100;

  ${media.xl`
    display: flex;
    width: 100%;
    position: absolute;
    overflow: visible;

    & > div {
      display: flex;
      margin: 0;
      margin-top: 50px;
      justify-content: center;
      min-width: 349px;
    }

    & > div > div {
      flex: unset;
    }
  `}
`;

export const Overlay = styled.div`
  ${media.xl`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  img {
    margin: 5px 10px 0 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  & span {
    margin-top: 6px;
  }
`;

export const ComingSoon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
  padding: 0 8px;
  height: 32px;
  border-radius: 30px;
  font-size: 10px;
  background-color: ${colors.solid.bgGrey};
  color: ${colors.solid.primaryBlue};
`;

export const LinkContainer = styled.div``;
