const games = [
  'US',
  'GB',
  'ES',
  'FR',
  'GP',
  'MQ',
  'GF',
  'RE',
  'YT',
  'MF',
  'PF',
  'WF',
  'NC',
  'NL',
  'IL',
  'LT',
  'BQ',
  'CW',
  'GI',
  'JE',
  'GR',
  'CR',
  'CU',
  'IR',
  'SY',
  'KP',
  'BE',
  'GR',
  'RS',
  'CZ',
  'PT',
];

export default { games } as Record<string, string[]>;
