import bchLogo from './assets/bitcoin-cash-logo.png';
import btcLogo from './assets/bitcoin-logo.png';
import buyCryptoIcon from './assets/buy-buy-crypto.png';
import sellCryptoIcon from './assets/buy-sell-crypto.png';
import companyAboutIcon from './assets/company-about.png';
import companyCareersIcon from './assets/company-careers.png';
import companySupportIcon from './assets/company-support.png';
import ethLogo from './assets/ethereum-logo.png';
import basicsIcon from './assets/learn-basics.png';
import getStartedIcon from './assets/learn-get-started.png';
import howBuyIcon from './assets/learn-how-buy.png';
import latestIcon from './assets/news-latest.png';
import marketDataIcon from './assets/products-market-data.png';
import merchantMapsIcon from './assets/products-merchant-maps.png';
import usdcLogo from './assets/usdc-logo.png';
import usdtLogo from './assets/usdt-logo.png';
import verseLogo from './assets/verse-logo.png';
import nftIcon from './assets/verse-nft.png';
import whitepaperIcon from './assets/verse-whitepaper.png';
import bitcoinLogoSm from './assets/bitcoin-logo-small.png';
import cardIcon from './assets/card.png';

type TIcons = Record<string, string>;

export const icons: TIcons = {
  'bch-logo': bchLogo,
  'btc-logo': btcLogo,
  'buy-crypto-icon': buyCryptoIcon,
  'sell-crypto-icon': sellCryptoIcon,
  'about-icon': companyAboutIcon,
  'careers-icon': companyCareersIcon,
  'support-icon': companySupportIcon,
  'eth-logo': ethLogo,
  'basics-icon': basicsIcon,
  'get-started-icon': getStartedIcon,
  'how-icon': howBuyIcon,
  'latest-icon': latestIcon,
  'market-icon': marketDataIcon,
  'merchant-icon': merchantMapsIcon,
  'usdc-logo': usdcLogo,
  'usdt-logo': usdtLogo,
  'verse-logo': verseLogo,
  'nft-icon': nftIcon,
  'whitepaper-icon': whitepaperIcon,
  'bitcoin-logo-sm': bitcoinLogoSm,
  'card-icon': cardIcon,
};
