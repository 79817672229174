import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { MAXMIND_URL } from '../settings';

export const useGeoLocation = () => {
  const [geoLocation, setGeoLocation] = useState<string>();

  const updateLocation = useCallback(async (): Promise<void> => {
    try {
      const { data } = await axios.get(MAXMIND_URL);

      localStorage.setItem('bitcoincom-country', data.countryCode);

      setGeoLocation(data.countryCode);
    } catch (error) {
      console.log('Error getting location', error);
    }
  }, []);

  useEffect(() => {
    const storedLocation = localStorage.getItem('bitcoincom-country');
    if (storedLocation) {
      setGeoLocation(storedLocation);
    }
    updateLocation();
  }, []);

  return geoLocation;
};
