import React, { FunctionComponent, ReactNode } from 'react';
import { ShouganaiWidget } from '@bitcoin-portal/mpc-widget';
import { useLinks } from '../../hooks/useLinks';
import Buttons from '../Buttons';
import Links from '../Links';
import Logo from '../Logo';
import MobileNav from '../MobileNav';
import {
  Container,
  InnerNavWrapper,
  NavLogoWrapper,
  SearchBarDesktop,
} from './styled';

interface Props {
  contrast: boolean;
  links: LinksStrings[];
  extraLink?: ReactNode;
  buttons: ButtonsStrings[];
  locale: string;
  logo?: ReactNode;
  logoUrl?: string;
  navbarAlign?: string;
  limitTo?: {
    paths: string[];
    keywords: (string | number)[];
    groups?: string[];
    et_route?: string;
  };
  showSearchBar: boolean;
  accounts?: boolean;
}

const Content: FunctionComponent<Props> = ({
  logo,
  logoUrl,
  contrast,
  links,
  extraLink,
  buttons,
  navbarAlign,
  locale,
  limitTo,
  showSearchBar,
  accounts = false,
}) => {
  const allLinks = useLinks(links);

  return (
    <InnerNavWrapper>
      <Container>
        <NavLogoWrapper>
          <Logo logo={logo} contrast={contrast} logoUrl={logoUrl} />
          <MobileNav
            contrast={contrast}
            links={allLinks}
            extraLink={extraLink}
            buttons={buttons}
            locale={locale}
            limitTo={limitTo}
            showSearchBar={showSearchBar}
          />
        </NavLogoWrapper>
        <Links
          contrast={contrast}
          links={allLinks}
          extraLink={extraLink}
          navbarAlign={navbarAlign}
        />
        {showSearchBar && (
          <SearchBarDesktop locale={locale} limitTo={limitTo} />
        )}
      </Container>
      <Buttons contrast={contrast} buttons={buttons} />
      {accounts && (
        <div
          style={{
            marginLeft: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ShouganaiWidget />
        </div>
      )}
    </InnerNavWrapper>
  );
};

export default Content;
