import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';
import SearchBar from '@bitcoin-portal/bitcoincom-pkg-components/dist/SearchBar';

export const InnerNavWrapper = styled.nav`
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: transparent;
  width: 100%;
  margin: auto;
  max-width: 1440px;
`;

export const NavLogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > a {
    display: flex;
  }

  ${media.xl`
    width: unset;
  `}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const SearchBarDesktop = styled(SearchBar as any)`
  display: none;

  ${media.xl`
    display: flex;
    justify-content: end;
  `}
`;

export const SearchBarWrapperResponsive = styled.div`
  display: flex;
  justify-content: end;
  flex-grow: 1;

  ${media.xl`
    display: none;
  `}
`;

export const SearchBarResponsive = styled(SearchBar as any)`
  display: flex;
  justify-content: end;

  ${media.xl`
    display: none;
  `}
`;
