import React, { ReactNode, Component } from 'react';
import { ThemeProvider } from 'styled-components';
import themes from '@bitcoin-portal/bitcoincom-pkg-theme';

import i18n from './i18n';

import StickyContainer from './components/StickyContainer';

import { LOCALES } from './settings';
import Content from './components/Content';

interface Props {
  contrast?: boolean;
  links?: LinksStrings[];
  extraLink?: ReactNode;
  buttons?: ButtonsStrings[];
  logo?: ReactNode;
  isReact?: boolean;
  locale?: string;
  callback?: ((locale: string) => void) | string;
  navbarAlign?: string;
  showSearchBar?: boolean;
  logoUrl?: string;
  limitSearchTo?: {
    paths: string[];
    keywords: (string | number)[];
    groups?: string[];
    et_route?: string;
  };
  accounts?: boolean;
}

interface State {
  contrast: boolean;
  locale?: string;
  links: LinksStrings[];
  buttons: ButtonsStrings[];
  social: SocialStrings[];
  logoUrl: string;
  country: string;
  navbarAlign: string;
}

class Navbar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      contrast = false,
      locale = 'en',
      logoUrl = 'https://www.bitcoin.com',
      links,
      buttons,
      navbarAlign,
    } = props;

    const res = i18n[locale] || i18n.en;

    this.setContrast = this.setContrast.bind(this);
    this.setLocale = this.setLocale.bind(this);

    this.state = {
      contrast,
      locale,
      links: links || res.links,
      buttons: buttons || res['account-buttons'],
      logoUrl: logoUrl || res.logo,
      social: [],
      country: '',
      navbarAlign: navbarAlign || 'right',
    };
  }

  static getDerivedStateFromProps(nextProps: Props, nextState: State): State {
    const { isReact = true, locale } = nextProps;

    let { contrast } = nextState;
    if (isReact && typeof nextProps.contrast !== 'undefined')
      contrast = nextProps.contrast;

    return {
      ...nextState,
      contrast,
      locale: isReact ? locale : nextState.locale,
    };
  }

  setContrast = (contrast: boolean): void => {
    this.setState({ contrast });
  };

  setLocale = (locale: string): void => {
    const { callback, links, buttons, logoUrl, navbarAlign } = this.props;
    if (typeof callback === 'string') {
      window[callback].call(this, locale);
    }

    if (typeof callback === 'function') {
      callback(locale);
    }

    if (LOCALES.includes(locale)) {
      const res = i18n[locale] || i18n.en;
      this.setState({
        locale,
        links: links || res.links,
        buttons: buttons || res['account-buttons'],
        logoUrl: logoUrl || res.logo,
        navbarAlign: navbarAlign || 'right',
      });
    }
  };

  render() {
    const { contrast, links, buttons, logoUrl, navbarAlign, locale } =
      this.state;
    const {
      logo,
      limitSearchTo,
      showSearchBar = true,
      extraLink,
      accounts,
    } = this.props;
    const theme = themes.light;

    return (
      <ThemeProvider theme={theme}>
        <StickyContainer contrast={contrast}>
          <Content
            logo={logo}
            links={links}
            extraLink={extraLink}
            buttons={buttons}
            logoUrl={logoUrl}
            contrast={contrast}
            navbarAlign={navbarAlign}
            showSearchBar={showSearchBar}
            locale={locale ?? 'en'}
            limitTo={limitSearchTo}
            accounts={accounts}
          />
        </StickyContainer>
      </ThemeProvider>
    );
  }
}

export default Navbar;
