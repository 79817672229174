import React, {
  FunctionComponent,
  ReactNode,
  useState,
  useRef,
  useEffect,
} from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Image, Video } from './styled';
import logoLight from '../../assets/logo_white.png';
import logoDark from '../../assets/logo_black.png';
import animatedLogo from '../../assets/logo_animated.mp4';

interface LogoProps {
  contrast?: boolean;
  logo?: ReactNode;
  logoUrl?: string;
}

const Logo: FunctionComponent<LogoProps> = ({
  contrast = false,
  logo,
  logoUrl,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoReady, setVideoReady] = useState(false);

  useEffect(() => {
    const checkVideoReady = () => {
      const video = videoRef.current;
      if (video && video.readyState >= 2) {
        setVideoReady(true);
      }
    };

    const timeout = setTimeout(checkVideoReady, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <a href={logoUrl} className="universal-menu-link">
      {logo || (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {!videoReady && (
            <Image
              src={contrast ? logoLight : logoDark}
              alt="Bitcoin.com logo"
            />
          )}
          <Video
            ref={videoRef}
            src={animatedLogo}
            autoPlay
            muted
            playsInline
            disablePictureInPicture
            display={videoReady ? 'block' : 'none'}
          />
        </span>
      )}
    </a>
  );
};

export default Logo;
