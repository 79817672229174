import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import themes, { colors, media } from '@bitcoin-portal/bitcoincom-pkg-theme';

interface WrapperProps {
  contrast?: boolean;
  height?: number | string;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ height, contrast }): FlattenSimpleInterpolation => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;s

    height: calc(${height} * 100);

    background-color: ${
      contrast ? themes.dark.palette.background.default : colors.solid.bgGrey
    }}

    box-sizing: border-box;
    z-index: 9999;
  `}

  ${media.xl`
    display: none;
  `}
`;

export const NavLogoWrapper = styled.div`
  display: flex;
  padding: 28px 30px 40px;
  justify-content: space-between;
  align-items: center;

  ${media.md`
    padding: 40px 70px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100vh;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin: auto;

  display: flex;
  flex-direction: column;
`;

export const ScrollContainer = styled.div`
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
