import React, { FunctionComponent } from 'react';
import Image from '@bitcoin-portal/bitcoincom-pkg-components/dist/Image';
import { Sublink, Subheading, TitleContainer, ComingSoon } from '../styled';
import { icons } from '../../../../icons';

interface Props {
  sublinkId?: string;
  url?: string;
  subheading?: string;
  icon?: string;
  alt?: string;
  text?: string;
  contrast?: boolean;
}

const SublinkComponent: FunctionComponent<Props> = ({
  sublinkId,
  url,
  subheading,
  icon,
  alt,
  text,
  contrast,
}) => {
  const sublinkIcon = icons[icon || ''];
  return (
    <Sublink
      href={url}
      className="universal-menu-link sublink"
      rel="canonical"
      contrast={contrast}
    >
      <TitleContainer>
        {sublinkIcon && alt && <Image src={sublinkIcon} alt={alt} width={23} />}
        {sublinkId}
        {text && (
          <>
            <span style={{ flex: '1 1 0' }} /> <ComingSoon>{text}</ComingSoon>
          </>
        )}
      </TitleContainer>
      <Subheading contrast={contrast}>{subheading}</Subheading>
    </Sublink>
  );
};

export default SublinkComponent;
