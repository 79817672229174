import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import media from '../../../media';

interface MobileMenuProps {
  contrast?: boolean;
  menuOpened?: boolean;
}

export default styled.button<MobileMenuProps>`
  ${({ menuOpened = false, contrast }): FlattenSimpleInterpolation => css`
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;

    & > svg {
      width: 20px;
      color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
    }

    ${media.xl`
      display: none;
    `}

    ${menuOpened &&
    css`
      display: flex;
      justify-content: flex-end;

      & > svg {
        color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
      }

      ${media.md`
          display: flex;
        `}
    `};
  `}
`;
